export interface IFolderItem {
  __typename: string;
  id: string;
  createdAt: string | null;
  updatedAt: string | null;
  createdBy: string | null;
  updatedBy: string | null;
  title: string;
  slug: string;
  referenceNumber: string;
  isReadOnly: boolean | null;
  access: string;
}

export interface IGenericFolder<T> {
  id: string;
  title: string;
  slug: string;
  path: IGenericFolder<T>[] | null;
  parent: IGenericFolder<T> | null;
  children: IGenericFolder<T>[] | null;
  createdAt: string | null;
  updatedAt: string | null;
  items: T[] | null;
  isReadOnly: boolean | null;
}

export type ITemplateFolder = IGenericFolder<ITemplate>;
export type IDocumentFolder = IGenericFolder<IDocument>;
export type IItemFolder = IGenericFolder<IFolderItem>;

export interface ITemplate extends IFolderItem {
  structure: ISection[];
  templateCategory: DocumentCategoryType;
  folder: ITemplateFolder | null;
  drafts: ITemplateDraft[];
}

export interface IDocument extends IFolderItem {
  structure: ISection[];
  contents: IContentValue[];
  documentCategory: DocumentCategoryType;
  folder: IDocumentFolder | null;
  drafts: IDocumentDraft[];
}

// tslint:disable
// graphql typescript definitions

export interface IGraphQLResponseRoot {
  data?: IQuery | IMutation;
  errors?: Array<IGraphQLResponseError>;
}

export interface IGraphQLResponseError {
  /** Required for all errors */
  message: string;
  locations?: Array<IGraphQLResponseErrorLocation>;
  /** 7.2.2 says 'GraphQL servers may provide additional entries to error' */
  [propName: string]: any;
}

export interface IGraphQLResponseErrorLocation {
  line: number;
  column: number;
}

export interface IQuery {
  __typename: 'Query';
  documents: Array<IDocument> | null;
  documentsByIdList: Array<IDocument>;
  document: IDocument | null;
  searchDocuments: Array<ISearchHit | null> | null;
  documentDraft: IDocumentDraft | null;
  breadcrumb: Array<Folder | null> | null;
  customer: ICustomer | null;
  customers: Array<ICustomer>;
  login: ILoginResult | null;
  user: IUser | null;
  users: Array<IUser>;

  /**
   * Rootfolder or specific folder
   */
  folder: Array<Folder | null>;

  /**
   * @deprecated "No longer supported"
   */
  folderHierarchy: ITemplateFolder | IDocumentFolder;

  /**
   * @deprecated "No longer supported"
   */
  folderDocumentsHierarchy: IDocumentFolder;
  getDocumentsTree: DocumentsTreePayload | null;
  getTemplatesTree: TemplatesTreePayload | null;

  /**
   * Document samples hierarchy
   * @deprecated "No longer supported"
   */
  folderHierarchySamples: any;
  templates: Array<ITemplate>;
  template: ITemplate | null;
  templateDraft: ITemplateDraft | null;
  logEntries: Array<ILogEntry>;
  uploadedFile: IFile | null;
  pagedTasks: IPagedTasks;
  messages: Array<IMessage>;
  inboxMessages: Array<IMessage>;
  periods: Array<IPeriod> | null;
  period: IPeriod | null;
  accessGroups: Array<IAccessGroup> | null;
  jobs: Array<JobResult> | null;
  archiveDocumentAttachments: IArchiveDocumentAttachments | null;
  backendGitCommit: IGitCommit | null;
  graphQLGitCommit: IGitCommit | null;
}

export interface IDocumentsOnQueryArguments {
  input?: IDocumentsInput | null;
}

export interface IDocumentsByIdListOnQueryArguments {
  ids?: Array<string> | null;
}

export interface IDocumentOnQueryArguments {
  id: string;
  version?: number | null;
}

export interface ISearchDocumentsOnQueryArguments {
  query: string;
}

export interface IDocumentDraftOnQueryArguments {
  id: string;
}

export interface IBreadcrumbOnQueryArguments {
  /**
   * id of specific folder
   */
  folder: string;

  /**
   * choose the folder type
   */
  type: FolderType;
}

export interface ICustomerOnQueryArguments {
  id: string;
}

export interface ILoginOnQueryArguments {
  email: string;
  password: string;
}

export interface IUserOnQueryArguments {
  id: string;
}

export interface IUsersOnQueryArguments {
  input?: IListUsersInput | null;
}

export interface IFolderOnQueryArguments {
  /**
   * id of specific folder
   */
  id?: string | null;

  /**
   * choose the folder type
   */
  type: FolderType;
}

export interface IFolderHierarchyOnQueryArguments {
  /**
   * choose the folder type
   * @default "DOCUMENTS"
   */
  type?: FolderType | null;
}

export interface IFolderDocumentsHierarchyOnQueryArguments {
  input?: IFolderDocumentsHierarchyInput | null;
}

export interface IGetDocumentsTreeOnQueryArguments {
  input?: IDocumentsTreeInput | null;
}

export interface IGetTemplatesTreeOnQueryArguments {
  input?: ITemplatesTreeInput | null;
}

export interface IFolderHierarchySamplesOnQueryArguments {
  /**
   * choose the folder type
   * @default "DOCUMENTS"
   */
  type?: FolderType | null;
}

export interface ITemplateOnQueryArguments {
  id: string;
}

export interface ITemplateDraftOnQueryArguments {
  id: string;
}

export interface ILogEntriesOnQueryArguments {
  id: string;
  limit?: number | null;
}

export interface IUploadedFileOnQueryArguments {
  id: string;
}

export interface IPagedTasksOnQueryArguments {
  input?: ITaskInput | null;
}

export interface IPeriodsOnQueryArguments {
  input?: IPeriodsInput | null;
}

export interface IPeriodOnQueryArguments {
  id: string;
  version?: number | null;
}

export interface IAccessGroupsOnQueryArguments {
  input?: IListAccessGroupsInput | null;
}

export interface IArchiveDocumentAttachmentsOnQueryArguments {
  id: string;
}

export interface IDocumentsInput {
  ids?: Array<string> | null;
  page?: number | null;
  itemsPerPage?: number | null;

  /**
   * filter deleted documents
   */
  deleted?: boolean | null;

  /**
   * get examples in the response
   */
  examples?: boolean | null;

  /**
   * gets documents by creatorTemplate
   */
  creatorTemplate?: string | null;
  updatedLastDays?: number | null;
}

export interface IDocument {
  __typename: 'Document';
  id: string;
  title: string;
  slug: string;
  deleted: boolean | null;
  structure: Array<ISection>;
  contents: Array<IContentValue>;
  folder: IDocumentFolder | null;
  path: Array<Folder> | null;
  drafts: Array<IDocumentDraft>;
  relatedTasks: IPagedTasks | null;
  reason: string | null;
  comment: string | null;
  outgoingReferences: Array<IDocument> | null;
  incomingReferences: Array<IDocument> | null;
  createdAt: string | null;
  createdBy: string | null;
  documentCategory: DocumentCategoryType;
  referenceNumber: string;
  controller: string | null;
  responsible: string | null;
  responsibleFor: Array<string | null> | null;
  status: Status | null;
  nextTaskAt: string | null;
  taskSchedulingInterval: string | null;
  taskDescription: string | null;
  updatedAt: string | null;
  updatedBy: string | null;
  validFrom: string | null;
  validUntil: string | null;
  isExample: boolean | null;
  permissions: Array<DocumentPermission> | null;
  creatorTemplate: ITemplate | null;
  taskDueDatePreWarningAmount: string | null;
  taskDueDatePreWarningUnit: string | null;
  taskDueDatePreWarningDescription: string | null;
  accessLevel: string | null;
  enablePreWarning: boolean;
}

type DocumentBase = IDocument | IDocumentDraft;

export interface IDocumentBase {
  __typename: 'DocumentBase';
  id: string;
  title: string;
  slug: string;
  structure: Array<ISection>;
  contents: Array<IContentValue>;
  folder: Folder | null;
  path: Array<Folder> | null;
  createdAt: string | null;
  createdBy: string | null;
  documentCategory: DocumentCategoryType;
  referenceNumber: string;
  controller: string | null;
  responsible: string | null;
  responsibleFor: Array<string | null> | null;
  status: Status | null;
  nextTaskAt: string | null;
  taskSchedulingInterval: string | null;
  taskDescription: string | null;
  updatedAt: string | null;
  updatedBy: string | null;
  validFrom: string | null;
  validUntil: string | null;
  isExample: boolean | null;
  permissions: Array<DocumentPermission> | null;
  creatorTemplate: ITemplate | null;
  taskDueDatePreWarningAmount: string | null;
  taskDueDatePreWarningUnit: string | null;
  taskDueDatePreWarningDescription: string | null;
  enablePreWarning: boolean;
}

export interface ISection {
  __typename: 'Section';
  id: string;
  title: string | null;
  fields: Array<IField>;
}

export interface IField {
  __typename: 'Field';
  id: string;
  label: string;
  size: FieldSize | null;
  type: FieldType;
  defaultValue: string | null;
  config: any | null;
}

export enum FieldSize {
  HALF = 'HALF',
  FULL = 'FULL',
}

export enum FieldType {
  TEXT = 'TEXT',
  BOOLEAN = 'BOOLEAN',
  INTEGER = 'INTEGER',
  DECIMAL = 'DECIMAL',
  COUNTRY = 'COUNTRY',
  CURRENCY = 'CURRENCY',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  REFERENCE = 'REFERENCE',
  LANGUAGE = 'LANGUAGE',
  LINK = 'LINK',
  FILE = 'FILE',
  SELECTION = 'SELECTION',
  RISK = 'RISK',
  RISKASSESSMENT = 'RISKASSESSMENT',
  EMAIL = 'EMAIL',
  RICHTEXT = 'RICHTEXT',
  PERIOD = 'PERIOD',
  PHONENUMBER = 'PHONENUMBER',
}

export interface IContentValue {
  __typename: 'ContentValue';
  id: string;
  value: string | null;
}

type Folder = IFolderDocuments | IFolderTemplates;

export interface IFolder {
  __typename: 'Folder';
  id: string;
  title: string;
  slug: string;
  path: Array<Folder>;
  parent: Folder | null;
  children: Array<Folder>;
  createdAt: string | null;
  updatedAt: string | null;
}

export enum DocumentCategoryType {
  confidential = 'confidential',
  internal = 'internal',
  open = 'open',
  external = 'external',
}

export enum Status {
  open = 'open',
  approved = 'approved',
  deprecated = 'deprecated',
}

export enum Country {
  AFG = 'Afghanistan ',
  ALA = 'Åland Island ',
  ALB = 'Albania ',
  DZA = 'Algeria ',
  ASM = 'American Samoa ',
  AND = 'Andorra ',
  AGO = 'Angola ',
  AIA = 'Anguilla ',
  ATA = 'Antarctica ',
  ATG = 'Antigua and Barbuda ',
  ARG = 'Argentina ',
  ARM = 'Armenia ',
  ABW = 'Aruba ',
  AUS = 'Australia ',
  AUT = 'Austria ',
  AZE = 'Azerbaijan ',
  BHS = 'Bahamas ',
  BHR = 'Bahrain ',
  BGD = 'Bangladesh ',
  BRB = 'Barbados ',
  BLR = 'Belarus ',
  BEL = 'Belgium ',
  BLZ = 'Belize ',
  BEN = 'Benin ',
  BMU = 'Bermuda ',
  BTN = 'Bhutan ',
  BOL = 'Bolivia (Plurinational State of) ',
  BES = 'Bonaire, Sint Eustatius and Saba ',
  BIH = 'Bosnia and Herzegovina ',
  BWA = 'Botswana ',
  BVT = 'Bouvet Island ',
  BRA = 'Brazil ',
  IOT = 'British Indian Ocean Territory ',
  BRN = 'Brunei Darussalam ',
  BGR = 'Bulgaria ',
  BFA = 'Burkina Faso ',
  BDI = 'Burundi ',
  KHM = 'Cambodia ',
  CMR = 'Cameroon ',
  CAN = 'Canada ',
  CPV = 'Cape Verde ',
  CYM = 'Cayman Islands ',
  CAF = 'Central African Republic ',
  TCD = 'Chad ',
  CHL = 'Chile ',
  CHN = 'China ',
  CXR = 'Christmas Island ',
  CCK = 'Cocos (Keeling) Islands ',
  COL = 'Colombia ',
  COM = 'Comoros ',
  COD = 'Congo (the Democratic Republic of the) ',
  COG = 'Congo ',
  COK = 'Cook Islands ',
  CRI = 'Costa Rica ',
  CIV = "Côte d'Ivoire ",
  HRV = 'Croatia ',
  CUB = 'Cuba ',
  CUW = 'Curaçao ',
  CYP = 'Cyprus ',
  CZE = 'Czechia ',
  DNK = 'Denmark ',
  DJI = 'Djibouti ',
  DMA = 'Dominica ',
  DOM = 'Dominican Republic ',
  ECU = 'Ecuador ',
  EGY = 'Egypt ',
  SLV = 'El Salvador ',
  GNQ = 'Equatorial Guinea ',
  ERI = 'Eritrea ',
  EST = 'Estonia ',
  SWZ = 'Eswatini ',
  ETH = 'Ethiopia ',
  FLK = 'Falkland Islands (Malvinas) ',
  FRO = 'Faroe Islands ',
  FJI = 'Fiji ',
  FIN = 'Finland ',
  FRA = 'France ',
  GUF = 'French Guiana ',
  PYF = 'French Polynesia ',
  ATF = 'French Southern Territories ',
  GAB = 'Gabon ',
  GMB = 'Gambia ',
  GEO = 'Georgia ',
  DEU = 'Germany ',
  GHA = 'Ghana ',
  GIB = 'Gibraltar ',
  GRC = 'Greece ',
  GRL = 'Greenland ',
  GRD = 'Grenada ',
  GLP = 'Guadeloupe ',
  GUM = 'Guam ',
  GTM = 'Guatemala ',
  GGY = 'Guernsey ',
  GIN = 'Guinea ',
  GNB = 'Guinea-Bissau ',
  GUY = 'Guyana ',
  HTI = 'Haiti ',
  HMD = 'Heard Island and McDonald Islands ',
  VAT = 'Holy See ',
  HND = 'Honduras ',
  HKG = 'Hong Kong ',
  HUN = 'Hungary ',
  ISL = 'Iceland ',
  IND = 'India ',
  IDN = 'Indonesia ',
  IRN = 'Iran (Islamic Republic of) ',
  IRQ = 'Iraq ',
  IRL = 'Ireland ',
  IMN = 'Isle of Man ',
  ISR = 'Israel ',
  ITA = 'Italy ',
  JAM = 'Jamaica ',
  JPN = 'Japan ',
  JEY = 'Jersey ',
  JOR = 'Jordan ',
  KAZ = 'Kazakhstan ',
  KEN = 'Kenya ',
  KIR = 'Kiribati ',
  PRK = "Korea (the Democratic People's Republic of) ",
  KOR = 'Korea (the Republic of) ',
  KWT = 'Kuwait ',
  KGZ = 'Kyrgyzstan ',
  LAO = "Lao People's Democratic Republic ",
  LVA = 'Latvia ',
  LBN = 'Lebanon ',
  LSO = 'Lesotho ',
  LBR = 'Liberia ',
  LBY = 'Libya ',
  LIE = 'Liechtenstein ',
  LTU = 'Lithuania ',
  LUX = 'Luxembourg ',
  MAC = 'Macao ',
  MDG = 'Madagascar ',
  MWI = 'Malawi ',
  MYS = 'Malaysia ',
  MDV = 'Maldives ',
  MLI = 'Mali ',
  MLT = 'Malta ',
  MHL = 'Marshall Islands ',
  MTQ = 'Martinique ',
  MRT = 'Mauritania ',
  MUS = 'Mauritius ',
  MYT = 'Mayotte ',
  MEX = 'Mexico ',
  FSM = 'Micronesia (Federated States of) ',
  MDA = 'Moldova (the Republic of) ',
  MCO = 'Monaco ',
  MNG = 'Mongolia ',
  MNE = 'Montenegro ',
  MSR = 'Montserrat ',
  MAR = 'Morocco ',
  MOZ = 'Mozambique ',
  MMR = 'Myanmar ',
  NAM = 'Namibia ',
  NRU = 'Nauru ',
  NPL = 'Nepal ',
  NLD = 'Netherlands ',
  NCL = 'New Caledonia ',
  NZL = 'New Zealand ',
  NIC = 'Nicaragua ',
  NER = 'Niger ',
  NGA = 'Nigeria ',
  NIU = 'Niue ',
  NFK = 'Norfolk Island ',
  MKD = 'North Macedonia ',
  MNP = 'Northern Mariana Islands ',
  NOR = 'Norway ',
  OMN = 'Oman ',
  PAK = 'Pakistan ',
  PLW = 'Palau ',
  PSE = 'Palestine, State of ',
  PAN = 'Panama ',
  PNG = 'Papua New Guinea ',
  PRY = 'Paraguay ',
  PER = 'Peru ',
  PHL = 'Philippines ',
  PCN = 'Pitcairn ',
  POL = 'Poland ',
  PRT = 'Portugal ',
  PRI = 'Puerto Rico ',
  QAT = 'Qatar ',
  REU = 'Réunion ',
  ROU = 'Romania ',
  RUS = 'Russian Federation ',
  RWA = 'Rwanda ',
  BLM = 'Saint Barthélemy ',
  SHN = 'Saint Helena, Ascension and Tristan da Cunha ',
  KNA = 'Saint Kitts and Nevis ',
  LCA = 'Saint Lucia ',
  MAF = 'Saint Martin (French part) ',
  SPM = 'Saint Pierre and Miquelon ',
  VCT = 'Saint Vincent and the Grenadines ',
  WSM = 'Samoa ',
  SMR = 'San Marino ',
  STP = 'Sao Tome and Principe ',
  SAU = 'Saudi Arabia ',
  SEN = 'Senegal ',
  SRB = 'Serbia ',
  SYC = 'Seychelles ',
  SLE = 'Sierra Leone ',
  SGP = 'Singapore ',
  SXM = 'Sint Maarten (Dutch part) ',
  SVK = 'Slovakia ',
  SVN = 'Slovenia ',
  SLB = 'Solomon Islands ',
  SOM = 'Somalia ',
  ZAF = 'South Africa ',
  SSD = 'South Sudan ',
  ESP = 'Spain ',
  LKA = 'Sri Lanka ',
  SDN = 'Sudan ',
  SUR = 'Suriname ',
  SJM = 'Svalbard and Jan Mayen ',
  SWE = 'Sweden ',
  CHE = 'Switzerland ',
  SYR = 'Syrian Arab Republic ',
  TWN = 'Taiwan ',
  TJK = 'Tajikistan ',
  TZA = 'Tanzania, United Republic of ',
  THA = 'Thailand ',
  TLS = 'Timor-Leste ',
  TGO = 'Togo ',
  TKL = 'Tokelau ',
  TON = 'Tonga ',
  TTO = 'Trinidad and Tobago ',
  TUN = 'Tunisia ',
  TUR = 'Turkey ',
  TKM = 'Turkmenistan ',
  TCA = 'Turks and Caicos Islands ',
  TUV = 'Tuvalu ',
  UGA = 'Uganda ',
  UKR = 'Ukraine ',
  ARE = 'United Arab Emirates ',
  GBR = 'United Kingdom of Great Britain and Northern Ireland ',
  UMI = 'United States Minor Outlying Islands ',
  USA = 'United States of America ',
  URY = 'Uruguay ',
  UZB = 'Uzbekistan ',
  VUT = 'Vanuatu ',
  VEN = 'Venezuela (Bolivarian Republic of) ',
  VNM = 'Viet Nam ',
  VGB = 'Virgin Islands (British) ',
  VIR = 'Virgin Islands (U. S.) ',
  WLF = 'Wallis and Futuna ',
  ESH = 'Western Sahara ',
  YEM = 'Yemen ',
  ZMB = 'Zambia ',
  ZWE = 'Zimbabwe ',
}

export enum Language {
  AA = 'Afar ',
  AB = 'Abkhaz ',
  AE = 'Avestan ',
  AF = 'Afrikaans ',
  AK = 'Akan ',
  AM = 'Amharic ',
  AN = 'Aragonese ',
  AR = 'Arabic ',
  AS = 'Assamese ',
  AV = 'Avaric ',
  AY = 'Aymara ',
  AZ = 'Azerbaijani ',
  BA = 'Bashkir ',
  BE = 'Belarusian ',
  BG = 'Bulgarian ',
  BH = 'Bihari ',
  BI = 'Bislama ',
  BM = 'Bambara ',
  BN = 'Bengali ',
  BO = 'Tibetan ',
  BR = 'Breton ',
  BS = 'Bosnian ',
  CA = 'Catalan ',
  CE = 'Chechen ',
  CH = 'Chamorro ',
  CO = 'Corsican ',
  CR = 'Cree ',
  CS = 'Czech ',
  CU = 'Church Slavic ',
  CV = 'Chuvash ',
  CY = 'Welsh ',
  DA = 'Danish ',
  DE = 'German ',
  DV = 'Divehi ',
  DZ = 'Dzongkha ',
  EE = 'Ewe ',
  EL = 'Greek ',
  EN = 'English ',
  EO = 'Esperanto ',
  ES = 'Spanish ',
  ET = 'Estonian ',
  EU = 'Basque ',
  FA = 'Persian ',
  FF = 'Fulah ',
  FI = 'Finnish ',
  FJ = 'Fijian ',
  FO = 'Faroese ',
  FR = 'French ',
  FY = 'Western Frisian ',
  GA = 'Irish ',
  GD = 'Gaelic ',
  GL = 'Galician ',
  GN = 'Guaraní ',
  GU = 'Gujarati ',
  GV = 'Manx ',
  HA = 'Hausa ',
  HE = 'Hebrew ',
  HI = 'Hindi ',
  HO = 'Hiri Motu ',
  HR = 'Croatian ',
  HT = 'Haitian ',
  HU = 'Hungarian ',
  HY = 'Armenian ',
  HZ = 'Herero ',
  IA = 'Interlingua ',
  ID = 'Indonesian ',
  IE = 'Interlingue ',
  IG = 'Igbo ',
  II = 'Sichuan Yi ',
  IK = 'Inupiaq ',
  IO = 'Ido ',
  IS = 'Icelandic ',
  IT = 'Italian ',
  IU = 'Inuktitut ',
  JA = 'Japanese ',
  JV = 'Javanese ',
  KA = 'Georgian ',
  KG = 'Kongo ',
  KI = 'Kikuyu ',
  KJ = 'Kuanyama ',
  KK = 'Kazakh ',
  KL = 'Kalaallisut ',
  KM = 'Khmer ',
  KN = 'Kannada ',
  KO = 'Korean ',
  KR = 'Kanuri ',
  KS = 'Kashmiri ',
  KU = 'Kurdish ',
  KV = 'Komi ',
  KW = 'Cornish ',
  KY = 'Kirghiz ',
  LA = 'latin ',
  LB = 'Luxembourgish ',
  LG = 'Ganda ',
  LI = 'Limburgish ',
  LN = 'Lingala ',
  LO = 'Lao ',
  LT = 'Lithuanian ',
  LU = 'Luba-Katanga ',
  LV = 'Latvian ',
  MG = 'Malagasy ',
  MH = 'Marshallese ',
  MI = 'Māori ',
  MK = 'Macedonian ',
  ML = 'Malayalam ',
  MN = 'Mongolian ',
  MO = 'Moldavian ',
  MR = 'Marathi ',
  MS = 'Malay ',
  MT = 'Maltese ',
  MY = 'Burmese ',
  NA = 'Nauru ',
  NB = 'Norwegian Bokmål ',
  ND = 'North Ndebele ',
  NE = 'Nepali ',
  NG = 'Ndonga ',
  NL = 'Dutch ',
  NN = 'Norwegian Nynorsk ',
  NO = 'Norwegian ',
  NR = 'South Ndebele ',
  NV = 'Navajo ',
  NY = 'Chichewa ',
  OC = 'Occitan ',
  OJ = 'Ojibwa ',
  OM = 'Oromo ',
  OR = 'Oriya ',
  OS = 'Ossetian ',
  PA = 'Panjabi ',
  PI = 'Pāli ',
  PL = 'Polish ',
  PS = 'Pashto ',
  PT = 'Portuguese ',
  QU = 'Quechua ',
  RM = 'Raeto-Romance ',
  RN = 'Kirundi ',
  RO = 'Romanian ',
  RU = 'Russian ',
  RW = 'Kinyarwanda ',
  SA = 'Sanskrit ',
  SC = 'Sardinian ',
  SD = 'Sindhi ',
  SE = 'Northern Sami ',
  SG = 'Sango ',
  SH = 'Serbo-Croatian ',
  SI = 'Sinhala ',
  SK = 'Slovak ',
  SL = 'Slovenian ',
  SM = 'Samoan ',
  SN = 'Shona ',
  SO = 'Somali ',
  SQ = 'Albanian ',
  SR = 'Serbian ',
  SS = 'Swati ',
  ST = 'Southern Sotho ',
  SU = 'Sundanese ',
  SV = 'Swedish ',
  SW = 'Swahili ',
  TA = 'Tamil ',
  TE = 'Telugu ',
  TG = 'Tajik ',
  TH = 'Thai ',
  TI = 'Tigrinya ',
  TK = 'Turkmen ',
  TL = 'Tagalog ',
  TN = 'Tswana ',
  TO = 'Tonga ',
  TR = 'Turkish ',
  TS = 'Tsonga ',
  TT = 'Tatar ',
  TW = 'Twi ',
  TY = 'Tahitian ',
  UG = 'Uighur ',
  UK = 'Ukrainian ',
  UR = 'Urdu ',
  UZ = 'Uzbek ',
  VE = 'Venda ',
  VI = 'Vietnamese ',
  VO = 'Volapük ',
  WA = 'Walloon ',
  WO = 'Wolof ',
  XH = 'Xhosa ',
  YI = 'Yiddish ',
  YO = 'Yoruba ',
  ZA = 'Zhuang ',
  ZH = 'Chinese ',
  ZU = 'Zulu ',
}

export enum DocumentPermission {
  GET = 'GET',
  CHECK_OUT = 'CHECK_OUT',
  CHECK_IN = 'CHECK_IN',
  COPY_DOCUMENT = 'COPY_DOCUMENT',
  COPY_DOCUMENTS = 'COPY_DOCUMENTS',
  MOVE_DOCUMENT = 'MOVE_DOCUMENT',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
  SEARCH = 'SEARCH',
}

export interface ITemplate {
  __typename: 'Template';
  id: string;
  createdAt: string | null;
  updatedAt: string | null;
  createdBy: string | null;
  updatedBy: string | null;
  title: string;
  slug: string;
  structure: Array<ISection>;
  templateCategory: DocumentCategoryType;
  referenceNumber: string;
  folder: ITemplateFolder | null;
  drafts: Array<ITemplateDraft>;
  isExample: boolean | null;
  assignable: boolean | null;
  permissions: Array<TemplatePermission> | null;
  createdDocuments: Array<IDocument> | null;
  accessLevel: string | null;
}

type TemplateBase = ITemplate | ITemplateDraft;

export interface ITemplateBase {
  __typename: 'TemplateBase';
  id: string;
  createdAt: string | null;
  updatedAt: string | null;
  createdBy: string | null;
  updatedBy: string | null;
  title: string;
  slug: string;
  structure: Array<ISection>;
  templateCategory: DocumentCategoryType;
  referenceNumber: string;
  folder: Folder | null;
  isExample: boolean | null;
  assignable: boolean | null;
  permissions: Array<TemplatePermission> | null;
  createdDocuments: Array<IDocument> | null;
}

export enum TemplatePermission {
  GET = 'GET',
  PUT = 'PUT',
  CHECK_OUT = 'CHECK_OUT',
  CHECK_IN = 'CHECK_IN',
  COPY_TEMPLATE = 'COPY_TEMPLATE',
  COPY_TEMPLATES = 'COPY_TEMPLATES',
  MOVE_TEMPLATE = 'MOVE_TEMPLATE',
  DELETE_TEMPLATE = 'DELETE_TEMPLATE',
  TO_DOCUMENT = 'TO_DOCUMENT',
}

export interface ITemplateDraft {
  __typename: 'TemplateDraft';
  id: string;
  createdAt: string | null;
  updatedAt: string | null;
  createdBy: string | null;
  updatedBy: string | null;
  title: string;
  slug: string;
  structure: Array<ISection>;
  templateCategory: DocumentCategoryType;
  referenceNumber: string;
  folder: Folder | null;
  template: ITemplate | null;
  isExample: boolean | null;
  assignable: boolean | null;
  permissions: Array<TemplatePermission> | null;
  createdDocuments: Array<IDocument> | null;
}

export interface IDocumentDraft {
  __typename: 'DocumentDraft';
  id: string;
  title: string;
  slug: string;
  structure: Array<ISection>;
  contents: Array<IContentValue>;
  folder: Folder | null;
  path: Array<Folder> | null;
  document: IDocument | null;
  createdAt: string | null;
  createdBy: string | null;
  documentCategory: DocumentCategoryType;
  referenceNumber: string;
  controller: string | null;
  responsible: string | null;
  responsibleFor: Array<string | null> | null;
  status: Status | null;
  nextTaskAt: string | null;
  taskSchedulingInterval: string | null;
  taskDescription: string | null;
  updatedAt: string | null;
  updatedBy: string | null;
  validFrom: string | null;
  validUntil: string | null;
  isExample: boolean | null;
  permissions: Array<DocumentPermission> | null;
  creatorTemplate: ITemplate | null;
  taskDueDatePreWarningAmount: string | null;
  taskDueDatePreWarningUnit: string | null;
  taskDueDatePreWarningDescription: string | null;
  enablePreWarning: boolean;
}

export interface IPagedTasks {
  __typename: 'PagedTasks';
  tasks: Array<ITask>;
  totalItems: number | null;
}

export interface ITask {
  __typename: 'Task';
  id: string;
  title: string;
  isCompleted: boolean;
  relatedDocument: IDocument;
  relatedDocumentVersion: number | null;
  responsibleEmails: Array<IResponsible> | null;
  controllerEmails: Array<IController> | null;
  dueAt: string | null;
  completedBy: string | null;
  completedAt: string | null;
  completionComment: string | null;
  description: string | null;
}

export interface IResponsible {
  __typename: 'Responsible';
  id: string | null;
  name: string | null;
  email: string | null;
}

export interface IController {
  __typename: 'Controller';
  id: string | null;
  name: string | null;
  email: string | null;
}

export interface ISearchHit {
  __typename: 'SearchHit';
  score: number;
  memberIri: string;
  title: string;
  path: Array<string | null> | null;
  highlights: IDocumentSearchHighlight | null;
}

export interface IDocumentSearchHighlight {
  __typename: 'DocumentSearchHighlight';
  title: Array<string | null> | null;
  structure: Array<string | null> | null;
  contents: Array<string | null> | null;
}

export enum FolderType {
  DOCUMENTS = 'DOCUMENTS',
  TEMPLATES = 'TEMPLATES',
}

export interface ICustomer {
  __typename: 'Customer';
  id: string;
  subscription: string;
  users: Array<IUser>;
  documents: Array<IDocument>;
  isExample: boolean | null;
  corporation: string | null;
}

export interface IUser {
  __typename: 'User';
  id: string;
  firstname: string;
  lastname: string;
  username: string | null;
  email: string | null;
  roles: Array<Role>;
  accessGroups: Array<IAccessGroup> | null;
  customer: ICustomer | null;
  enabled: boolean | null;
  lastLogin: string | null;
}

export enum Role {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_CONTENTADMIN = 'ROLE_CONTENTADMIN',
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_USER = 'ROLE_USER',
}

export interface IAccessGroup {
  __typename: 'AccessGroup';
  id: string;
  title: string;
  users: Array<IUser> | null;
  accessRights: Array<IAccessGroupRights> | null;
}

export interface IAccessGroupRights {
  __typename: 'AccessGroupRights';

  /**
   * id of the AccessGroupRights
   */
  id: string;

  /**
   * custom id of the type
   * for example an id of a folder
   */
  subject: string;
  type: AccessGroupType;
  access: AccessVariant | null;
}

export enum AccessGroupType {
  CUSTOMER = 'CUSTOMER',
  FOLDER_DOCUMENT = 'FOLDER_DOCUMENT',
  FOLDER_TEMPLATE = 'FOLDER_TEMPLATE',
  UNKNOWN = 'UNKNOWN',
}

export enum AccessVariant {
  none = 'none',
  read = 'read',
  edit = 'edit',
}

export interface ILoginResult {
  __typename: 'LoginResult';
  token: string;
  user: IUser | null;
}

export interface IListUsersInput {
  id?: Array<string> | null;
  filter?: IFilterListUsersInput | null;
}

export interface IFilterListUsersInput {
  isAdministrator?: boolean | null;
}

export interface IFolderDocumentsHierarchyInput {
  where?: IFolderDocumentsHierarchyWhereInput | null;
  options?: IFolderDocumentsHierarchyOptionsInput | null;
}

export interface IFolderDocumentsHierarchyWhereInput {
  creatorTemplate?: string | null;
}

export interface IFolderDocumentsHierarchyOptionsInput {
  excludeEmptyFolders?: boolean | null;
}

export interface IDocumentsTreeInput {
  where?: IDocumentsTreeWhereInput | null;
  options?: IDocumentsTreeOptionsInput | null;
}

export interface IDocumentsTreeWhereInput {
  creatorTemplate?: string | null;
  samples?: boolean | null;
}

export interface IDocumentsTreeOptionsInput {
  excludeEmptyFolders?: boolean | null;
}

type DocumentsTreePayload = IDocumentsTreePayloadResult | INotFound;

export interface IDocumentsTreePayloadResult {
  __typename: 'DocumentsTreePayloadResult';
  result: any | null;
}

export interface INotFound {
  __typename: 'NotFound';
  key: string;
}

type PayloadException = INotFound;

export interface IPayloadException {
  __typename: 'PayloadException';
  key: string;
}

export interface ITemplatesTreeInput {
  where?: ITemplatesTreeWhereInput | null;
  options?: ITemplatesTreeOptionsInput | null;
}

export interface ITemplatesTreeWhereInput {
  samples?: boolean | null;
}

export interface ITemplatesTreeOptionsInput {
  excludeEmptyFolders?: boolean | null;
}

type TemplatesTreePayload = ITemplatesTreePayloadResult | INotFound;

export interface ITemplatesTreePayloadResult {
  __typename: 'TemplatesTreePayloadResult';
  result: any | null;
}

export interface ILogEntry {
  __typename: 'LogEntry';
  id: string;
  action: string | null;
  objectClass: string | null;
  objectId: string | null;
  loggedAt: string | null;
  version: number | null;
  username: string | null;
  structure: Array<ISection | null>;
  updatedAt: string | null;
  document: IDocument | null;
}

export interface IFile {
  __typename: 'File';
  id: string;
  path: string;
  filename: string;
  mimeType: string;
}

export interface ITaskInput {
  controllerEmails?: Array<string> | null;
  dueAt?: ITaskDueAtInput | null;
  isCompleted?: boolean | null;
  relatedDocument?: Array<string> | null;
  responsibleEmails?: Array<string> | null;
  completionComment?: string | null;
  description?: string | null;
  relatedDocumentVersion?: number | null;
  page?: number | null;
  orderDirection?: string | null;
  orderBy?: string | null;
}

export interface ITaskDueAtInput {
  after?: string | null;
  before?: string | null;
  strictlyAfter?: string | null;
  strictlyBefore?: string | null;
}

export interface IPagedTasks {
  __typename: 'PagedTasks';
  tasks: Array<ITask>;
  totalItems: number | null;
}

export interface IMessage {
  __typename: 'Message';
  id: string;
  subject: string | null;
  body: string | null;
  sender: string | null;
  recipient: string | null;
  readAt: string | null;
  sentAt: string | null;
}

export interface IPeriodsInput {
  ids?: Array<string> | null;
  page?: number | null;
  responsibles?: Array<any> | null;
  relatedDocument?: Array<string> | null;
  itemsPerPage?: number | null;
  dueAt?: IPeriodsDueAtInput | null;
}

export interface IPeriodsDueAtInput {
  after?: string | null;
  before?: string | null;
  strictlyAfter?: string | null;
  strictlyBefore?: string | null;
}

export interface IPeriod {
  __typename: 'Period';
  id: string | null;
  title: string | null;
  relatedDocument: IDocument | null;
  relatedDocumentVersion: number | null;
  fieldId: string | null;
  applicableInterval: string | null;
  responsibles: Array<IResponsible | null> | null;
  controllers: Array<IResponsible | null> | null;
  dueAt: string | null;
  isCompleted: boolean | null;
  completedBy: string | null;
  completedAt: string | null;
  completionComment: string | null;
  prewarningStart: string | null;
  description: string | null;
}

export interface IListAccessGroupsInput {
  id?: Array<string> | null;
  type?: AccessGroupType | null;
}

type JobResult = IArchiveDocumentAttachments;

export interface IArchiveDocumentAttachments {
  __typename: 'ArchiveDocumentAttachments';
  id: string;
  status: JobStatus;
  createdAt: any | null;
  updatedAt: any | null;
  createdBy: string | null;
  updatedBy: string | null;
  result: IJobDoneResult | null;
  affectedDocuments: Array<IDocument> | null;
  additionalMediaObjects: Array<string> | null;
}

type Job = IArchiveDocumentAttachments;

export interface IJob {
  __typename: 'Job';
  id: string;
  status: JobStatus;
  createdAt: any | null;
  updatedAt: any | null;
  createdBy: string | null;
  updatedBy: string | null;
  result: IJobDoneResult | null;
}

export enum JobStatus {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
  DONE = 'DONE',
}

export interface IJobDoneResult {
  __typename: 'JobDoneResult';
  mediaObjectId: string | null;
}

export interface IGitCommit {
  __typename: 'GitCommit';
  gitCommit: string | null;
}

export interface IMutation {
  __typename: 'Mutation';
  checkoutDocument: IDocumentDraft | null;
  copyDocument: IDocument | null;
  updateDocument: IDocument | null;
  deleteDocument: boolean;
  checkinDocumentDraft: IDocument | null;
  saveDocumentDraft: IDocumentDraft | null;
  createDocumentFromTemplate: IDocument | null;
  checkoutTemplate: ITemplateDraft | null;
  copyTemplate: ITemplate | null;
  checkinTemplateDraft: ITemplate | null;
  saveTemplateDraft: ITemplateDraft | null;
  deleteTemplate: boolean;
  templateToDocument: IDocument | null;

  /**
   * @deprecated "No longer supported"
   */
  toggleUserRole: IUser | null;

  /**
   * @deprecated "No longer supported"
   */
  toggleUserState: IUser | null;
  updateUser: UserResult | null;
  inviteDifUser: IInviteDifUserRequest | null;
  createFolder: Folder | null;
  copyFolder: Folder | null;
  updateFolder: Folder | null;
  deleteFolder: boolean | null;
  createTemplate: ITemplate | null;
  completeTask: ITask | null;
  uncompleteTask: ITask | null;
  uploadFile: IFile;
  createCustomer: ICustomer;
  createMessage: IMessage | null;
  markReadMessage: IMessage | null;
  createMassMessage: IMassMessageResult | null;
  createTask: ITask | null;
  updatePeriod: IPeriod | null;
  createAccessGroup: IAccessGroup | null;
  updateAccessGroup: IAccessGroup | null;
  deleteAccessGroup: boolean | null;
  createArchiveDocumentAttachments: IArchiveDocumentAttachments | null;
}

export interface ICheckoutDocumentOnMutationArguments {
  id: string;
}

export interface ICopyDocumentOnMutationArguments {
  id: string;
  folder: string;
  input?: ICopyDocumentInput | null;
}

export interface IUpdateDocumentOnMutationArguments {
  id: string;
  input: IUpdateDocumentInput;
}

export interface IDeleteDocumentOnMutationArguments {
  id: string;
}

export interface ICheckinDocumentDraftOnMutationArguments {
  id: string;
  input?: IDocumentDraftInput | null;
  checkinData: ICheckinDataInput;
}

export interface ISaveDocumentDraftOnMutationArguments {
  id: string;
  input: IDocumentDraftInput;
}

export interface ICreateDocumentFromTemplateOnMutationArguments {
  /**
   * template id
   */
  id: string;

  /**
   * folder where put in the new document
   */
  folder: string;
  input: ICreateDocumentInput;
}

export interface ICheckoutTemplateOnMutationArguments {
  id: string;
}

export interface ICopyTemplateOnMutationArguments {
  id: string;
  folder: string;
  input?: ICopyTemplateInput | null;
}

export interface ICheckinTemplateDraftOnMutationArguments {
  id: string;
  input?: ITemplateDraftInput | null;
  checkinData: ICheckinDataInput;
}

export interface ISaveTemplateDraftOnMutationArguments {
  id: string;
  input: ITemplateDraftInput;
}

export interface IDeleteTemplateOnMutationArguments {
  id: string;
}

export interface ITemplateToDocumentOnMutationArguments {
  id: string;
  folder: string;
  input?: IDocumentCreateInput | null;
}

export interface IToggleUserRoleOnMutationArguments {
  id: string;
  role: Role;
}

export interface IToggleUserStateOnMutationArguments {
  id: string;
}

export interface IUpdateUserOnMutationArguments {
  id: string;
  input: IUpdateUserInput;
}

export interface IInviteDifUserOnMutationArguments {
  email: string;
  firstname: string;
  lastname: string;
  subscription: string;
  accessGroups: Array<string>;
}

export interface ICreateFolderOnMutationArguments {
  type: FolderType;
  input: IFolderCreateInput;
}

export interface ICopyFolderOnMutationArguments {
  id: string;
  type: FolderType;
  input: IFolderCopyInput;
}

export interface IUpdateFolderOnMutationArguments {
  id: string;
  type: FolderType;
  input: IFolderUpdateInput;
}

export interface IDeleteFolderOnMutationArguments {
  id: string;
  type: FolderType;
}

export interface ICreateTemplateOnMutationArguments {
  input: ITemplateCreateInput;
}

export interface ICompleteTaskOnMutationArguments {
  id: string;
  completionComment: string;
}

export interface IUncompleteTaskOnMutationArguments {
  id: string;
}

export interface IUploadFileOnMutationArguments {
  file: any;
}

export interface ICreateCustomerOnMutationArguments {
  subscription: string;
}

export interface ICreateMessageOnMutationArguments {
  input: IMessageInput;
}

export interface IMarkReadMessageOnMutationArguments {
  id: string;
}

export interface ICreateMassMessageOnMutationArguments {
  input: IMassMessageInput;
}

export interface ICreateTaskOnMutationArguments {
  input: ICreateTaskInput;
}

export interface IUpdatePeriodOnMutationArguments {
  input: IUpdatePeriodInput;
}

export interface ICreateAccessGroupOnMutationArguments {
  input: ICreateAccessGroupInput;
}

export interface IUpdateAccessGroupOnMutationArguments {
  id: string;
  input: IUpdateAccessGroupInput;
}

export interface IDeleteAccessGroupOnMutationArguments {
  id: string;
}

export interface ICreateArchiveDocumentAttachmentsOnMutationArguments {
  input: IArchiveDocumentAttachmentsInput;
}

export interface ICopyDocumentInput {
  title?: string | null;
}

export interface IUpdateDocumentInput {
  folder?: string | null;
  deleted?: boolean | null;
}

export interface IDocumentDraftInput {
  title: string;
  contents?: Array<IContentsInput> | null;
  documentCategory?: DocumentCategoryType | null;
  referenceNumber?: string | null;
  status?: Status | null;
  validFrom?: string | null;
  validUntil?: string | null;
  responsible?: string | null;
  responsibleFor?: string | null;
  controller?: string | null;
  nextTaskAt?: string | null;
  taskSchedulingInterval?: string | null;
  taskDescription?: string | null;
  taskDueDatePreWarningAmount?: string | null;
  taskDueDatePreWarningUnit?: string | null;
  taskDueDatePreWarningDescription?: string | null;
  enablePreWarning: boolean;
}

export interface IContentsInput {
  id: string;
  value?: string | null;
}

export interface ICheckinDataInput {
  reason: string;
  comment: string;
}

export interface ICreateDocumentInput {
  documentDraft: IDocumentDraftInput;
  checkinData?: ICheckinDataInput | null;
}

export interface ICopyTemplateInput {
  title?: string | null;
}

export interface ITemplateDraftInput {
  title: string;
  structure: Array<IStructureInput>;
  templateCategory: DocumentCategoryType;
  referenceNumber: string;
  assignable?: boolean | null;
}

export interface IStructureInput {
  id?: string | null;
  title?: string | null;
  fields: Array<IFieldInput>;
}

export interface IFieldInput {
  id?: string | null;
  label: string;
  size?: FieldSize | null;
  type: FieldType;
  defaultValue?: string | null;
  config?: any | null;
}

export interface IDocumentCreateInput {
  title?: string | null;
}

export interface IUpdateUserInput {
  role?: Role | null;
  toggleState?: boolean | null;
  accessGroups?: Array<string> | null;
  isAdministrator?: boolean | null;
}

type UserResult = IUser | IUserNotPromoted | IUnhandledError;

/**
 * user is not promoted by DIF-API
 */
export interface IUserNotPromoted {
  __typename: 'UserNotPromoted';
  key: string;
  id: string;
}

/**
 * error is not handled
 */
export interface IUnhandledError {
  __typename: 'UnhandledError';
  key: string;
}

export interface IInviteDifUserRequest {
  __typename: 'InviteDifUserRequest';
  email: string;
  firstname: string;
  lastname: string;
  subscription: string;
  accessGroups: Array<IAccessGroup> | null;
}

export interface IFolderCreateInput {
  title: string;
  parent?: string | null;
}

export interface IFolderCopyInput {
  parent: string;
  title?: string | null;
}

export interface IFolderUpdateInput {
  title?: string | null;
  parent?: string | null;
}

export interface ITemplateCreateInput {
  title: string;
  folder: string;
  templateCategory: DocumentCategoryType;
  referenceNumber: string;
}

export interface IMessageInput {
  subject?: string | null;
  body?: string | null;
  recipient?: string | null;
}

export interface IMassMessageInput {
  subject?: string | null;
  body?: string | null;
  submissionType?: SubmissionType | null;
}

export enum SubmissionType {
  all_customer_users = 'all_customer_users',
  all_customer_admins = 'all_customer_admins',
}

export interface IMassMessageResult {
  __typename: 'MassMessageResult';
  subject: string | null;
  body: string | null;
  submissionType: string | null;
}

export interface ICreateTaskInput {
  relatedDocument: string;
}

export interface IUpdatePeriodInput {
  isCompleted?: boolean | null;
  completionComment?: string | null;
  id: string;
}

export interface ICreateAccessGroupInput {
  title: string;
  accessRights?: Array<IAccessGroupRightsInput> | null;
  users?: Array<string> | null;
}

export interface IAccessGroupRightsInput {
  type: AccessGroupType;
  subject: string;
  id?: string | null;
  access: AccessVariant;
}

export interface IUpdateAccessGroupInput {
  title?: string | null;
  accessRights?: Array<IAccessGroupRightsInput> | null;
  users?: Array<string> | null;
}

export interface IArchiveDocumentAttachmentsInput {
  affectedDocumentIds?: Array<string> | null;
  additionalMediaObjectIds?: Array<string> | null;
}

export interface IFolderDocuments {
  __typename: 'FolderDocuments';
  id: string;
  title: string;
  slug: string;
  path: Array<Folder>;
  parent: Folder | null;
  children: Array<Folder>;
  createdAt: string | null;
  updatedAt: string | null;
  documents: Array<IDocument> | null;
}

export interface IFolderTemplates {
  __typename: 'FolderTemplates';
  id: string;
  title: string;
  slug: string;
  path: Array<Folder>;
  parent: Folder | null;
  children: Array<Folder>;
  createdAt: string | null;
  updatedAt: string | null;
  templates: Array<ITemplate> | null;
}

export interface IAccessGroupsInput {
  id: string;
  type?: AccessGroupType | null;
}

export enum CacheControlScope {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum AccessLevel {
  FULL = 'FULL',
  LIMITED = 'LIMITED',
  TITLE = 'TITLE',
}
